import React from "react";
import homeIcon from "../assets/images/home_icon.svg";
import { useNavigate } from "react-router-dom";

function HomeNavigationItem() {
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-col justify-center items-center w-[8rem] h-[8rem] relative"
      onClick={() => navigate("/")}
    >
      <img
        src={homeIcon}
        alt={"Home icon"}
        style={{
          boxShadow: "0px 4px 33px rgba(237, 178, 88, 1)",
          borderRadius: "50%",
        }}
        className="object-contain self-center aspect-square w-[4rem]"
      />
      <div className="absolute pt-[5.4rem] text-sm">Home</div>
    </button>
  );
}

export default HomeNavigationItem;
