import ModalBottomSheet from "./ModalBottomSheet/ModalBottomSheet";
import Button from "./Button";
import dollarIcon from "../assets/images/dollar_icon.png";
import { useMemo } from "react";
import {
  useCompleteSocialMediaTaskMutation,
  useMarkSocialMediaTaskAsClickedMutation,
} from "../services/official";
import { useEffect, useState } from "react";
import CongratulationsModalBottomContent from "./CongratulationsModalBottomContent";

const SocialMediaTaskBottomSheet = ({
  icon,
  title,
  handleAction,
  actionButtonText,
  rewardInUsd,
  isOpen,
  close,
  taskId,
  moderationStartedAt,
}) => {
  const [markAsClicked] = useMarkSocialMediaTaskAsClickedMutation();
  const [complete, completeResult] = useCompleteSocialMediaTaskMutation();
  const [shouldShowError, setShouldShowError] = useState(false);

  const [moderationTime, setModerationTime] = useState(() => {
    const time = new Date() - Date.parse(moderationStartedAt);
    return time;
  });

  useEffect(() => {
    setModerationTime(new Date() - Date.parse(moderationStartedAt));
    const interval = setInterval(() => {
      setModerationTime(new Date() - Date.parse(moderationStartedAt));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [moderationStartedAt]);

  const isModerationOngoing = useMemo(
    () => moderationTime < 3600000,
    [moderationTime]
  );

  useEffect(() => {
    if (completeResult.isError) {
      setShouldShowError(true);
    }

    setTimeout(() => {
      setShouldShowError(false);
    }, 3000);
  }, [completeResult.isError]);

  const numberOfMinutesLeft = useMemo(() => {
    return Math.floor((3600000 - moderationTime) / 60000);
  }, [moderationTime]);

  return (
    <ModalBottomSheet isOpen={isOpen} close={close}>
      {completeResult.isSuccess && (
        <CongratulationsModalBottomContent
          result={completeResult}
          chipReward={rewardInUsd * 57}
        />
      )}
      {!completeResult.isSuccess && (
        <div className="flex flex-col items-center gap-4 w-[70%] pb-4">
          <img src={icon} alt={title} className="w-16 h-16" />
          <p className="text-2xl font-bold">{title}</p>
          <Button
            text={actionButtonText}
            className="mt-4"
            variant="third"
            onClick={() => {
              handleAction();
              markAsClicked(taskId);
            }}
          />
          {isModerationOngoing && !shouldShowError && (
            <div className="text-sm text-center">
              Wait {numberOfMinutesLeft}{" "}
              {numberOfMinutesLeft > 1 ? "minutes" : "minute"} for moderation
              check to claim the prize.
            </div>
          )}
          {shouldShowError && (
            <div className="text-sm text-center text-red-500">
              {completeResult.error?.data.message ?? "An error occurred."}
            </div>
          )}
          <div className="flex flex-row gap-2 items-center justify-center text-md mb-4">
            <img src={dollarIcon} alt="Dollar Icon" className="w-8 h-8" />+ $
            {rewardInUsd}
          </div>
          <Button
            text={"Check"}
            variant="primary"
            isDisabled={isModerationOngoing || shouldShowError}
            onClick={() => complete(taskId)}
          />
        </div>
      )}
    </ModalBottomSheet>
  );
};

export default SocialMediaTaskBottomSheet;
