import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import airdropCoinIcon from "../assets/images/airdrop_coin_icon.svg";
import SlotMachineReel from "./SlotMachineReel/SlotMachineReel";
import slotsScoreBackground from "../assets/images/slots_score_background.png";
import { useCountUp } from "react-countup";
import scoreCounterScound from "../assets/sounds/coin_win_effect.wav";
import reelSpinSound from "../assets/sounds/reel_spin.mp3";
import leverPullSound from "../assets/sounds/lever_pull.mp3";
import useSound from "use-sound";
import SizeVaryingCounter from "./SizeVaryingCounter";
import airdropSound from "../assets/sounds/airdrop_sound.wav";

const SlotMachine = forwardRef(({}, ref) => {
  const [winningAmountInChips, setWinningAmountInChips] = useState(0);
  const [airdropPointsReward, setAirdropPointsReward] = useState(0);
  const reel1Ref = useRef(null);
  const reel2Ref = useRef(null);
  const reel3Ref = useRef(null);
  const reel4Ref = useRef(null);
  const reel5Ref = useRef(null);
  const [isCounterVisible, setCounterVisible] = useState(false);

  const { reset: resetUsdCounter, update: updateUsdCounter } = useCountUp({
    ref: "usd-counter",
    start: 0,
    end: 0,
    delay: 0,
    duration: 3,
    decimals: 2,
    prefix: "$",
    useEasing: true,
  });

  const { reset: resetAirdropCounter, update: updateAirdropCounter } =
    useCountUp({
      ref: "airdrop-counter",
      start: 0,
      end: 0,
      delay: 0,
      duration: 3,
      decimals: 2,
      prefix: "",
      useEasing: true,
    });

  const [playScoreCounterSound, { stop: stopScoreCounterSound }] =
    useSound(scoreCounterScound);
  const [playReelSpinSound, { stop: stopReelSpinSound }] =
    useSound(reelSpinSound);

  const [playAirDropSound, { stop: stopAirDropSound }] = useSound(airdropSound);

  const [playLeverPullSound, { stop: stopLeverPullSound }] = useSound(
    leverPullSound,
    { volume: 0.5 }
  );

  const [winningType, setWinningType] = useState(null);

  useImperativeHandle(ref, () => ({
    spin: async ({
      reel1Position,
      reel2Position,
      reel3Position,
      reel4Position,
      reel5Position,
    }) => {
      setCounterVisible(false);
      setTimeout(() => {
        resetUsdCounter();
      }, 50);
      playLeverPullSound();
      await new Promise((resolve) => setTimeout(resolve, 400));
      playReelSpinSound();
      await Promise.all([
        reel1Ref.current.spin(reel1Position),
        reel2Ref.current.spin(reel2Position),
        reel3Ref.current.spin(reel3Position),
        reel4Ref.current.spin(reel4Position),
        reel5Ref.current.spin(reel5Position),
      ]);
    },

    showAirdropPoints: (data) => {
      resetAirdropCounter();
      playAirDropSound();
      setAirdropPointsReward(data.airdropPointsReward);
      setWinningType("airdrop");
      setCounterVisible(true);

      updateAirdropCounter(data.airdropPointsReward);
      return new Promise((resolve) => setTimeout(resolve, 3000));
    },

    showPayLinesWithRewards: async (
      {
        reel1Position,
        reel2Position,
        reel3Position,
        reel4Position,
        reel5Position,
        winningAmountInChips,
        payLines,
      },
      updateCounter = true
    ) => {
      setWinningAmountInChips(winningAmountInChips);
      setWinningType("usd");
      const reelSetup = [
        reel1Position,
        reel2Position,
        reel3Position,
        reel4Position,
        reel5Position,
      ];

      const sortedPayLines = [...payLines].sort(
        (a, b) => a.rewardInChips - b.rewardInChips
      );
      let displayedWinnings = 0;
      for (const payLine of sortedPayLines) {
        const positions = payLine.relativePositions;
        [reel1Ref, reel2Ref, reel3Ref, reel4Ref, reel5Ref].forEach(
          (reel, index) => {
            if (positions[index] !== null) {
              reel.current?.highlightSymbol(reelSetup[index] + positions[index]);
            }
          }
        );
        playScoreCounterSound();
        if (updateCounter) {
          displayedWinnings += payLine.rewardInChips;
          setCounterVisible(true);
          updateUsdCounter((displayedWinnings / 57).toFixed(2));
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));

        [reel1Ref, reel2Ref, reel3Ref, reel4Ref, reel5Ref].forEach((reel) => {
          reel.current?.highlightSymbol(null);
        });
      }
    },

    isSpinning: () =>
      reel1Ref.current?.isSpinning() ||
      reel2Ref.current?.isSpinning() ||
      reel3Ref.current?.isSpinning() ||
      reel4Ref.current?.isSpinning() ||
      reel5Ref.current?.isSpinning(),
  }));

  useEffect(() => {
    return () => {
      stopScoreCounterSound();
      stopReelSpinSound();
      stopLeverPullSound();
      stopAirDropSound();
    };
  }, [stopScoreCounterSound, stopReelSpinSound]);

  return (
    <div>
      <div className="flex flex-col items-center justify-start">
        <div
          style={{
            backgroundImage: `url(${slotsScoreBackground})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-52 h-52 text-xl flex items-center justify-center mt-[-50%] mb-[-15%]"
        >
          <div
            className="flex flex-row items-center justify-center"
            style={
              !isCounterVisible ||
              (winningAmountInChips <= 0 && airdropPointsReward <= 0)
                ? { display: "none" }
                : null
            }
          >
            {winningType === "airdrop" && (
              <img
                src={airdropCoinIcon}
                alt="Airdrop Coin Icon"
                className="w-6 h-6 mr-2"
              />
            )}

            <SizeVaryingCounter
              finalAmount={(winningAmountInChips / 57).toFixed(2)}
              id={"usd-counter"}
              maxWidth={window.innerWidth * 0.2}
              style={winningType !== "usd" ? { display: "none" } : null}
            />
            <SizeVaryingCounter
              finalAmount={airdropPointsReward.toFixed(2)}
              id={"airdrop-counter"}
              maxWidth={window.innerWidth * 0.17}
              style={winningType !== "airdrop" ? { display: "none" } : null}
            />
          </div>
        </div>
        <div className="w-screen flex flex-row justify-center h-min mt-1">
          <SlotMachineReel ref={reel1Ref} />
          <SlotMachineReel ref={reel2Ref} />
          <SlotMachineReel ref={reel3Ref} />
          <SlotMachineReel ref={reel4Ref} />
          <SlotMachineReel ref={reel5Ref} isLast />
        </div>
      </div>
    </div>
  );
});

export default SlotMachine;
