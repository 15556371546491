import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    betSize: "",
    autoplayGames: "",
    isLocked: false,
    isSpinning: false
};

const slotsSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {
        setBetSize(state, action) {
            const betSize = action.payload;
            state.betSize = betSize;
        },
        setAutoplayGames(state, action) {
            const autoplayGames = action.payload;
            state.autoplayGames = autoplayGames;
        },
        setLocked(state, action) {
            const isLocked = action.payload;
            state.isLocked = isLocked;
        },
        setSpinning(state, action) {
            const isSpinning = action.payload;
            state.isSpinning = isSpinning;
        },
    },
});

export const {
    setBetSize,
    setAutoplayGames,
    setLocked,
    setSpinning
} = slotsSlice.actions;
export default slotsSlice.reducer;

