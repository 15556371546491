import React, { useEffect, useState } from "react";
import { preloadImages, getImages } from "../utils/preloading";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import {
  useAuthenticateWithTelegramMutation,
  useLazyGetUserQuery,
  useLazyGetReferralStatisticsQuery,
  useLazyGetSettingsQuery,
  useLazyGetDepositWalletQuery,
  useLazyGetSocialMediaTasksQuery,
} from "../services/official";
import splashScreen from "../assets/images/splash_screen.webp";
import backgroundImage from "../assets/images/background.png";

const SplashScreen = ({ onFinish }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [, initData] = useInitData();
  const [authenticate] = useAuthenticateWithTelegramMutation();
  const [fetchUser] = useLazyGetUserQuery();
  const [fetchReferralStatistics] = useLazyGetReferralStatisticsQuery();
  const [fetchSettings] = useLazyGetSettingsQuery();
  const [fetchDepositWallet] = useLazyGetDepositWalletQuery();
  const [fetchSocialMediaTasks] = useLazyGetSocialMediaTasksQuery();

  useEffect(() => {
    const loadAssets = async () => {
      try {
        let completedSteps = 0;
        const images = getImages();
        const totalSteps = images.length + 6; // images + other tasks

        const { promise: preloadPromise } = preloadImages((loaded) => {
          completedSteps = loaded;
          setProgress((completedSteps / totalSteps) * 100);
        });

        await preloadPromise;

        completedSteps = images.length; // All images loaded

        const { userId } = await authenticate({ initData }).unwrap();
        completedSteps += 1;
        setProgress((completedSteps / totalSteps) * 100);

        const fetchPromises = [
          fetchUser(userId)
            .unwrap()
            .then(() => {
              completedSteps += 1;
              setProgress((completedSteps / totalSteps) * 100);
            }),
          fetchReferralStatistics({ userId })
            .unwrap()
            .then(() => {
              completedSteps += 1;
              setProgress((completedSteps / totalSteps) * 100);
            }),
          fetchSettings()
            .unwrap()
            .then(() => {
              completedSteps += 1;
              setProgress((completedSteps / totalSteps) * 100);
            }),
          fetchDepositWallet({ userId })
            .unwrap()
            .then(() => {
              completedSteps += 1;
              setProgress((completedSteps / totalSteps) * 100);
            }),
          fetchSocialMediaTasks({ userId })
            .unwrap()
            .then(() => {
              completedSteps += 1;
              setProgress((completedSteps / totalSteps) * 100);
            }),
        ];

        await Promise.all(fetchPromises);
      } catch (error) {
        console.error("Error preloading assets:", error);
      } finally {
        setProgress(100);
        setTimeout(() => {
          setLoading(false);
          onFinish(); // Signal that loading is finished
        }, 500);
      }
    };

    document.body.style.backgroundImage = `url(${backgroundImage})`;

    loadAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFinish]);

  if (loading) {
    return (
      <div
        className="flex flex-col justify-center items-center w-full h-screen text-xl relative"
        style={{
          backgroundImage: `url(${splashScreen})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="fixed top-0 left-0 w-full h-0.5">
          <div
            className="h-full"
            style={{
              width: `${progress}%`,
              background: "linear-gradient(to bottom, #FFB617, #FF7C17)",
              transition: "width 0.2s ease-in-out",
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default SplashScreen;
