import React from "react";
import { ImageButton } from "./ImageButton";

function GameCard({ imageSrc, onClick, title, alt }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div style={{
        width: "156px",
        height: "138px",
      }}>
      <ImageButton
        onClick={onClick}
        imageSrc={imageSrc}
        alt={alt}
      />
      </div>
      <div className="text-lg">{title}</div>
    </div>
  );
}

export default GameCard;
