import TaskCard from "./TaskCard";
import dailyLoginIcon from "../assets/images/daily_login_icon.png";
import { useSelector } from "react-redux";
import { useGetDailyLoginStatisticsQuery } from "../services/official";
import { useCallback, useMemo, useState } from "react";
import DailyLoginRewardsBottomSheet from "./DailyLoginRewardsBottomSheet";

const DailyLoginTaskCard = ({}) => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetDailyLoginStatisticsQuery({ userId });
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const isGoalCompleted = useMemo(() => {
    return (
      data !== null &&
      data !== undefined &&
      data?.daysInARow >= data?.nextDailyLoginGoal
    );
  }, [data]);

  return (
    <>
      <TaskCard
        icon={dailyLoginIcon}
        title={"Daily login"}
        reward={data && `$${data?.nextDailyLoginReward / 57}`}
        progress={{
          unitsCompleted: data?.daysInARow,
          goal: data?.nextDailyLoginGoal,
        }}
        subtitle={isGoalCompleted && "Collect your daily reward"}
        buttonText={isGoalCompleted && "Daily reward"}
        onButtonClick={isGoalCompleted && openModal}
      />
      <DailyLoginRewardsBottomSheet
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default DailyLoginTaskCard;
