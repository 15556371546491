const Switch = ({ text, isEnabled, onClick, withBackground = true }) => {
  const circlePosition = isEnabled ? "justify-end" : "justify-start";
  const backgroundDependentStyle = withBackground
    ? "gradient-border h-14 text-sm p-3"
    : "text-lg";

  return (
    <button
      onClick={onClick}
      className={`flex flex-row items-center justify-between ${backgroundDependentStyle} w-full rounded-2xl`}
      style={
        withBackground
          ? {
              background:
                "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2))",
            }
          : null
      }
    >
      {text}
      <div
        style={{
          background: !isEnabled
            ? "linear-gradient(to bottom, rgba(248, 246, 252, 0.4), rgba(218, 215, 228, 1))"
            : "linear-gradient(to right, #FF8517, #FFAE17)",
        }}
        className={`w-12 h-6 p-0.5 rounded-2xl flex items-center ${circlePosition}`}
      >
        <div
          className="rounded-full h-5 w-5 bg-[#FFFEEE]"
          style={{ boxShadow: "3.09px 3.09px 6.18px rgba(0, 0, 0, 0.25)" }}
        ></div>
      </div>
    </button>
  );
};

export default Switch;
