import { useRef } from "react";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import { useKeyboardVisibility } from "../utils/mobileUtils";
import InputField from "../components/InputField";
import YouWinPopUp from "../components/YouWinPopUp";
import MinorWinEffect from "../components/MinorWinEffect";
import SlotMachine from "../components/SlotMachine";
import chipIcon from "../assets/images/chip_icon.svg";
import {
  useAutoplay,
  useSpin,
  useValidateBetSize,
  useValidateAutoplayGames,
} from "../utils/slots";
import { setBetSize, setAutoplayGames } from "../features/slotsSlice";
import { useDispatch } from "react-redux";
import AirdropEffect from "../components/AirdropEffect";
import "./SlotsScreen.css";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";
import SlideUpAnimation from "../animations/SlideUpAnimation";

const SlotsScreen = () => {
  const dispatch = useDispatch();
  const youWinPopUpRef = useRef();
  const airdropEffectRef = useRef();
  const minorWinEffectRef = useRef();
  const slotMachineRef = useRef();

  const { betSize, autoplayGames, isLocked, isSpinning } = useSelector(
    (state) => state.slots
  );
  const isKeyboardVisible = useKeyboardVisibility();
  const { isValid: isBetSizeValid, errorMessage: betSizeErrorMessage } =
    useValidateBetSize();
  const {
    isValid: isAutoplayGamesValid,
    errorMessage: autoplayGamesErrorMessage,
  } = useValidateAutoplayGames();

  const spin = useSpin({
    slotMachineRef,
    minorWinEffectRef,
    youWinPopUpRef,
    airdropEffectRef,
  });
  const autoplay = useAutoplay({
    airdropEffectRef,
    slotMachineRef,
    minorWinEffectRef,
    youWinPopUpRef,
  });

  return (
    <>
      <YouWinPopUp ref={youWinPopUpRef} />
      <AirdropEffect ref={airdropEffectRef} />
      <div
        className="flex flex-col justify-between items-center h-[calc(100vh-5rem)] w-full overflow-hidden"
        style={{
          minHeight: isKeyboardVisible
            ? `max(110vh, calc(100vh + 150px))`
            : "0",
        }}
      >
        <div className="flex-grow flex flex-row items-center justify-center w-full h-full overflow-hidden z-[4] absolute top-0">
          <ScaleUpAnimation>
            <SlotMachine ref={slotMachineRef} />
          </ScaleUpAnimation>
          <MinorWinEffect ref={minorWinEffectRef} />
        </div>
        <div className="flex flex-col p-5 w-full absolute bottom-4 z-[9]">
          <SlideUpAnimation>
            <div className="flex flex-row items-end justify-between mb-4">
              <InputField
                type="number"
                inputMode="numeric"
                pattern="^\d+?$"
                isPrimary
                label={
                  <div className="flex flex-row gap-2 items-center">
                    Bet size
                    <img src={chipIcon} className="w-6 h-6" />
                  </div>
                }
                className="w-24"
                value={betSize}
                onChange={(e) => dispatch(setBetSize(e.target.value))}
                errorMessage={!isSpinning && betSizeErrorMessage}
                isDisabled={isSpinning || isLocked}
              />
              <Button
                isDisabled={!isBetSizeValid || isSpinning || isLocked}
                variant="secondary"
                text="Spin"
                onClick={spin}
              />
            </div>
            <div className="flex flex-row items-end justify-between">
              <InputField
                isPrimary
                label="Autoplay Games"
                className="w-24"
                type="number"
                inputMode="numeric"
                pattern="^\d+?$"
                value={autoplayGames}
                onChange={(e) => dispatch(setAutoplayGames(e.target.value))}
                errorMessage={!isSpinning && autoplayGamesErrorMessage}
                isDisabled={isSpinning || isLocked}
              />
              <Button
                variant="secondary"
                text="Autoplay"
                isDisabled={
                  !isAutoplayGamesValid ||
                  !isBetSizeValid ||
                  isSpinning ||
                  isLocked
                }
                onClick={autoplay}
              />
            </div>
          </SlideUpAnimation>
        </div>
      </div>
    </>
  );
};

export default SlotsScreen;
