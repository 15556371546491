import ReactModal from "react-modal";
import { ImageButton } from "../ImageButton";
import closeIcon from "../../assets/images/close_icon.webp";
import "./ModalPopUp.css";

const ModalPopUp = ({ isOpen, close, title, children, showCloseButton = true }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: "pop-up",
        afterOpen: "pop-up-open",
        beforeClose: "pop-up-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay-open",
        beforeClose: "overlay-close",
      }}
      closeTimeoutMS={500} // Duration of the animation in milliseconds
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-row items-center justify-center mt-6">
          <div className="text-2xl text-center">{title}</div>
          {showCloseButton && (
            <div className="absolute pr-6 right-0">
              <ImageButton
                imageSrc={closeIcon}
                alt="Close icon"
                onClick={close}
                className="w-8 h-8"
              />
            </div>
          )}
        </div>
        <div className="flex-grow flex items-center justify-center px-6">
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalPopUp;
