const ProgressBar = ({ progress }) => {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #FFB617, #FF7C17)",
      }}
      className="flex h-max rounded-2xl pr-0.5 pl-[0.06rem] py-[0.06rem] items-center"
    >
      <div className="flex items-center rounded-2xl w-full h-2 bg-[#4A2D69] p-[0.06rem]">
        <div
          style={{
            background: "linear-gradient(to bottom, #FFB617, #FF7C17)",
            width: `${progress * 100}%`,
            display: progress === 0 ? "none" : "block",
          }}
          className="flex h-full rounded-2xl pr-0.5 pl-[0.06rem] py-[0.06rem] items-center"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
