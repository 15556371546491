import DropdownMenu from "../components/DropdownMenu";
import InputField from "../components/InputField";
import Checkbox from "../components/Checkbox";
import { useState, useMemo } from "react";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../services/official";
import { useRequestPayoutMutation } from "../services/official";
import { useKeyboardVisibility } from "../utils/mobileUtils";
import PayoutStatusPopUp from "../components/PayoutStatusPopUp";

const PayoutTabContent = () => {
  const currencies = ["USDT (ERC-20)", "USDT (TRC-20)"];
  const currencyIds = ["TetherErc20", "TetherTrc20"];
  const [amount, setAmount] = useState("");
  const isKeyboardVisible = useKeyboardVisibility();
  const [address, setAddress] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [requestPayout, result] = useRequestPayoutMutation();
  const [isModalOpen, setModalOpen] = useState(false);

  const { id } = useSelector((state) => state.user);
  const { data } = useGetUserQuery(id);

  const isAddressValid = useMemo(() => address.length > 0, [address]);
  const isAmountValid = useMemo(() => {
    const decimalPattern = /^\d+(\.\d{1,2})?$/;
    return amount.length > 0 && decimalPattern.test(amount);
  }, [amount]);

  return (
    <>
      <PayoutStatusPopUp
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        result={result}
      />
      <div
        className="flex flex-col items-start justify-start p-5 overflow-auto"
        style={{
          minHeight: isKeyboardVisible
            ? `max(110vh, calc(100vh + 150px))`
            : "0",
        }}
      >
        <div className="text-xl">
          Your Game Winnings :
          <span className="text-[#FFD91D]">
            {" "}
            {(data?.withdrawableChipBalance / 57).toFixed(2)}$
          </span>
        </div>
        <div className="text-sm opacity-80">
          After you win money in games, you can withdraw it here. It will be
          deducted from your chip balance.
        </div>
        <div className="text-lg mt-6">
          Frozen Balance in USD :
          <span className="text-[#FFD91D]">
            {" "}
            {(data?.frozenChipBalance / 57).toFixed(2)}$
          </span>
        </div>
        <div className="text-sm opacity-80">
          Rewards earned outside of games are frozen at first. Deposit $1 to
          unfreeze it, and $2 will be added to your chip balance.
        </div>
        <div className="mt-5 w-full flex flex-row gap-3 justify-center items-end mb-1">
          <div className="w-7/12 flex flex-col">
            <InputField
              type="number"
              inputMode="decimal"
              pattern="^\d+(\.\d{1,2})?$"
              step="0.01"
              label="Amount To Withdraw"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              errorMessage={
                isAmountValid || amount.length == 0
                  ? null
                  : "Enter a valid USD decimal"
              }
            />
          </div>
          <div className="w-5/12">
            <DropdownMenu
              label="Currency"
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              items={currencies}
            />
          </div>
        </div>
        <div className="mt-5 w-full mb-1">
          <InputField
            label="Your Withdrawal Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="mt-5 mb-24 w-full">
          <Button
            isDisabled={!isAddressValid || !isAmountValid}
            text="Withdraw"
            onClick={async () => {
              setModalOpen(true);
              try {
                const result = await requestPayout({
                  address,
                  chipsToWithdraw: Math.floor(
                    Number.parseFloat(amount.replace(",", ".")) * 57
                  ),
                  currency: currencyIds[selectedIndex],
                }).unwrap();
                if (result.isSuccess) {
                  setAmount("");
                  setAddress("");
                }
              } catch (e) {}
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PayoutTabContent;
