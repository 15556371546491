import chipIcon from "../assets/images/chip_icon.svg";

const ChipExchangeRatio = () => {
  return (
    <div className="text-2xl">
      $1 = <img src={chipIcon} alt="Coin icon" className="w-7 inline-block" />{" "}
      57
    </div>
  );
};

export default ChipExchangeRatio;
