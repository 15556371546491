import { HeaderContainer } from "./HeaderContainer";

const StandardHeader = ({ title, rightElement }) => {
  return (
    <HeaderContainer>
      <div className="flex flex-row w-full justify-between items-center pt-6">
        <div className="font-medium text-2xl self-start">
          {title && title}
        </div>
        {rightElement}
      </div>
    </HeaderContainer>
  );
};

export default StandardHeader;
