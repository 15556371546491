import { useSelector } from "react-redux";
import {
  useGetPayoutHistoryQuery,
  useGetUserQuery,
} from "../services/official";
import PayoutHistoryCard from "../components/PayoutHistoryCard";
import { getTransactionLink } from "../utils/blockchain";
import { useMemo } from "react";

const PayoutHistoryScreen = () => {
  const { id } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(id);
  const { data } = useGetPayoutHistoryQuery({ userId: id });
  const currencies = {
    TetherErc20: "ERC-20",
    TetherTrc20: "TRC-20",
  };

  const statuses = {
    Completed: "Completed",
    WaitingForApproval: "Waiting for verification",
    Rejected: "Rejected",
    Failed: "Failed"
  };

  const payoutRequests = useMemo(
    () => data?.payoutRequests && [...data?.payoutRequests],
    [data?.payoutRequests]
  );

  return (
    <div className="pt-3 pb-32">
      {payoutRequests?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .map((payout, index) => (
          <PayoutHistoryCard
            key={index}
            currency={currencies[payout.currency]}
            amount={`${(payout.chipAmount / 57).toFixed(2)}`}
            status={statuses[payout.status]}
            username={user.userName}
            date={Date.parse(payout.createdAt)}
            transactionLink={
              payout.status === "Completed" &&
              getTransactionLink(
                payout.currency,
                payout.transactionHash,
                payout.network
              )
            }
          />
        ))}
    </div>
  );
};

export default PayoutHistoryScreen;
