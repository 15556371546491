import { useNavigate, useLocation } from "react-router-dom";
import Tab from "./Tab";
import { useCallback } from "react";

const TabBar = ({ leftTitle, rightTitle, leftLink, rightLink }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToLeftLink = useCallback(() => {
    navigate(leftLink);
  }, [leftLink]);

  const navigateToRightLink = useCallback(() => {
    navigate(rightLink);
  }, [rightLink]);

  return (
    <>
      <div className="flex flex-row items-center h-18 border rounded-2xl p-2.5 mx-5 mt-5 border-[#FF8217] border bg-transparent">
        <div className="w-1/2">
          <Tab
            title={leftTitle}
            isSelected={location.pathname === leftLink}
            onClick={navigateToLeftLink}
          />
        </div>
        <div className="w-1/2">
          <Tab
            title={rightTitle}
            isSelected={location.pathname === rightLink}
            onClick={navigateToRightLink}
          />
        </div>
      </div>
    </>
  );
};

export default TabBar;
