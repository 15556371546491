import { useState } from "react";
import arrowDownIcon from "../assets/images/arrow_down_icon.svg";

const DropdownMenu = ({
  items,
  label,
  selectedIndex,
  setSelectedIndex,
  isCentered = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative">
      <div className="text-xl">{label}</div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex flex-row h-12 w-full justify-between items-center gradient-border rounded-2xl mt-3 px-3 w-35"
        style={{
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
        }}
      >
        <div className="font-medium text-xs self-center text-center w-30">
          {items[selectedIndex]}
        </div>
        <img
          src={arrowDownIcon}
          alt="arrow down icon"
          className="w-4 h-4 ml-2"
        />
      </button>
      {isOpen && (
        <div
          style={{
            background:
              "linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%)",
          }}
          className="absolute w-full bg-white border border-[#FF8217] pl-3 rounded-2xl mt-1 z-[2] backdrop-blur-[5px] py-1"
        >
          {items.map((text, index) => (
            <button
              key={index}
              onClick={() => {
                setSelectedIndex(index);
                setIsOpen(false);
              }}
              className={`w-full h-min flex ${
                isCentered ? "justify-center" : "justify-start"
              } items-center my-1.5 text-xs`}
            >
              {text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
