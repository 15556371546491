import * as majorWinEffectAnimation from "../assets/animations/major_win_effect.json";
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import Lottie from "react-lottie";
import MinorWinEffect from "./MinorWinEffect";
import { useCountUp } from "react-countup";
import SizeVaryingCounter from "./SizeVaryingCounter";
import majorWinSound from "../assets/sounds/major_win_sound.mp3";
import useSound from "use-sound";

const YouWinPopUp = forwardRef(({}, ref) => {
  const minorWinEffectRef = useRef();
  const [isMajorWinEffectPlaying, setMajorWinEffectPlaying] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [winningAmountInUSD, setWinningAmountInUSD] = useState(0);
  const [isCounterVisible, setCounterVisible] = useState(false);
  const [playMajorWinSound, { stop: stopMajorWinSound }] =
    useSound(majorWinSound);

  const playPromiseResolveRef = useRef(null);

  useImperativeHandle(ref, () => ({
    play: async (winningAmountInChips) => {
      playMajorWinSound();
      if (isMajorWinEffectPlaying) return;
      setWinningAmountInUSD(winningAmountInChips / 57);
      setTimeout(() => {
        setCounterVisible(true);
        update(winningAmountInChips / 57);
      }, 900);
      setMajorWinEffectPlaying(true);

      return Promise.all([
        new Promise((resolve) => {
          playPromiseResolveRef.current = resolve;
        }),
        minorWinEffectRef.current.play(),
      ]);
    },
    isPlaying: () => {
      return isMajorWinEffectPlaying;
    },
  }));

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      stopMajorWinSound();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const animationOriginalWidth = 1080;
  const animationOriginalHeight = 1920;

  const scale = Math.max(
    screenWidth / animationOriginalWidth,
    screenHeight / animationOriginalHeight
  );

  const scaledWidth = animationOriginalWidth * scale;
  const scaledHeight = animationOriginalHeight * scale;

  const croppedWidthPerSide = (scaledWidth - screenWidth) / 2;
  const croppedHeightPerSide = (scaledHeight - screenHeight) / 2;

  const popupWidth = 602;
  const popupHeight = 648;
  const popupX = (animationOriginalWidth - popupWidth) / 2;
  const popupY = (animationOriginalHeight - popupHeight) / 2;

  const Z = (612 / 648) * 100;
  const fieldWidth = popupWidth * (136 / 602);
  const fieldHeight = popupHeight * (47 / 648);

  const fieldX = popupX + (popupWidth - fieldWidth) / 2;
  const fieldY = popupY + popupHeight * (Z / 100);

  // Map field position to screen coordinates
  const screenFieldX =
    fieldX * scale - croppedWidthPerSide + (fieldWidth * scale) / 4;
  const screenFieldY =
    fieldY * scale - croppedHeightPerSide + (fieldHeight * scale) / 2;

  // Ensure the counter stays within the screen bounds
  const counterStyle = useMemo(
    () => ({
      position: "absolute",
      left: `${screenFieldX}px`,
      top: `${screenFieldY}px`,
      transform: "translateY(-50%)",
      zIndex: 3,
      whiteSpace: "nowrap",
      maxWidth: `${fieldWidth * scale}px`,
      display: isCounterVisible ? "inline-block" : "none",
    }),
    [screenFieldX, screenFieldY, fieldWidth, scale, isCounterVisible]
  );

  // Function to adjust font size based on text length

  const { reset, update } = useCountUp({
    ref: "pop-up-counter",
    start: 0,
    end: 0,
    delay: 0,
    duration: 3.7,
    prefix: "$",
    useEasing: true,
    decimals: 2,
  });

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998]"
        style={
          !isMajorWinEffectPlaying && !minorWinEffectRef.current?.isPlaying()
            ? { display: "none" }
            : {}
        }
      >
        <div className="relative w-full h-full z-[1]">
          <SizeVaryingCounter
            finalAmount={winningAmountInUSD}
            id="pop-up-counter"
            style={counterStyle}
            maxWidth={0.95 * scale * fieldWidth}
          />
          <Lottie
            isClickToPauseDisabled={true}
            options={{
              loop: false,
              autoplay: true,
              animationData: majorWinEffectAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            isStopped={!isMajorWinEffectPlaying}
            isPaused={!isMajorWinEffectPlaying}
            height="100%"
            width="100%"
            eventListeners={[
              {
                eventName: "complete",
                callback: () => {
                  setCounterVisible(false);
                  setTimeout(() => {
                    setWinningAmountInUSD(0);
                    setMajorWinEffectPlaying(false);
                    reset();
                  }, 100);
                  if (playPromiseResolveRef.current) {
                    playPromiseResolveRef.current();
                    playPromiseResolveRef.current = null;
                  }
                },
              },
            ]}
          />
        </div>
      </div>
      <MinorWinEffect ref={minorWinEffectRef} />
    </>
  );
});

export default YouWinPopUp;
