import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";

const QrCode = ({ value }) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      data: value,
      dotsOptions: {
        color: "#ffffff",
        type: "extra-rounded", // Makes all the lines (dots) rounded
      },
      backgroundOptions: {
        color: "transparent",
      },
    });

    if (qrCodeRef.current.firstChild) {
      qrCodeRef.current.removeChild(qrCodeRef.current.firstChild);
    }
    qrCode.append(qrCodeRef.current);
  }, [value]);

  return (
    <div
      className="flex items-center justify-center rounded-xl aspect-square w-min gradient-border p-2"
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.12) 100%)",
      }}
    >
      <div ref={qrCodeRef} />{" "}
    </div>
  );
};

export default QrCode;
