import React from "react";
import selectedIconBackground from "../assets/images/selected_icon_background.svg";
import { Link, useLocation } from "react-router-dom";

function NavigationItem({ icon, label, to }) {
  const location = useLocation();
  const isSelected = location.pathname.startsWith(to);

  return (
    <Link to={to}>
      <div className="flex flex-col justify-center items-center pt-7">
        <div>
          <img
            src={icon}
            alt={`${label} icon`}
            style={{
              backgroundImage: isSelected
                ? `url(${selectedIconBackground})`
                : "",
            }}
            className="object-contain self-center aspect-square"
          />
        </div>
        <div className="mt-1.5 text-sm">{label}</div>
      </div>
    </Link>
  );
}

export default NavigationItem;
