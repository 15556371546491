import { motion } from "framer-motion";

const SlideUpAnimation = ({ children }) => {
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
      style={{ transformOrigin: 'center' }} // Add this line
      layout
    >
      {children}
    </motion.div>
  );
};

export default SlideUpAnimation;
