import React, { Suspense } from "react";
import GameCard from "../components/GameCard";
import pokerCard from "../assets/images/poker_card.png";
import diceCard from "../assets/images/dice_card.png";
import slotGameCard from "../assets/images/slots_card.png";
import rouletteCard from "../assets/images/roulette_card.png";
import { useNavigate } from "react-router-dom";
import "../components/Animations.css";
import "./SlotsScreen.css";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";

function HomeScreen() {
  const navigate = useNavigate();

  return (
    <Suspense>
      <ScaleUpAnimation>
        <div className="flex flex-col justify-center items-center gap-3 mt-24">
          <div className="flex flex-row gap-5">
            <GameCard
              onClick={() => navigate("/game/slots")}
              imageSrc={slotGameCard}
              title="Slots"
              alt="Slot game card"
            />
            <GameCard imageSrc={diceCard} title="Dice" alt="Dice card" />
          </div>

          <div className="flex flex-row gap-5">
            <GameCard
              imageSrc={rouletteCard}
              title="Roulette"
              alt="Roulette card"
            />
            <GameCard imageSrc={pokerCard} title="Poker" alt="Poker card" />
          </div>
        </div>
      </ScaleUpAnimation>
    </Suspense>
  );
}

export default HomeScreen;
