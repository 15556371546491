import TabBar from "../components/TabBar";
import PayoutTabContent from "./PayoutTabContent";

const PayoutScreen = () => {
  return (
    <>
      <TabBar
        leftTitle={"Payout"}
        rightTitle={"Live payouts"}
        leftLink={"/payout"}
        rightLink={"/payout/live-payouts"}
      />
      <PayoutTabContent />
    </>
  );
};

export default PayoutScreen;
