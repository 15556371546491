const Tab = ({ title, isSelected, onClick }) => {
  const selectedStyle = {
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
  };

  const selectedClasses = isSelected ? " gradient-border rounded-2xl " : "";

  return (
    <button
      onClick={onClick}
      className={
        "flex flex-row h-12 w-full justify-center items-center" +
        selectedClasses
      }
      style={isSelected ? selectedStyle : null}
    >
      <div className="font-medium text-lg self-center text-center">{title}</div>
    </button>
  );
};

export default Tab;
