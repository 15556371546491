import primaryBackgroundButton from "../assets/images/primary_button_background.svg";
import thirdBackgroundButton from "../assets/images/third_button_background.svg";

const Button = ({ text, isDisabled = false, onClick, variant = "primary" }) => {
  const variantStyling = {
    primary: "w-full h-24 w-full text-4xl",
    secondary: "w-full h-12 w-36 text-xl",
    third: "h-8 w-24 text-xs",
  };

  const variantMargin = {
    primary: "mb-5",
    secondary: "mb-2",
    third: "mb-1",
  };

  const variantBackground = {
    primary: primaryBackgroundButton,
    secondary: primaryBackgroundButton,
    third: thirdBackgroundButton,
  };

  return (
    <button
      className={` ${variantStyling[variant]} bg-no-repeat bg-center bg-contain flex items-center justify-center`}
      style={{
        backgroundImage: `url(${variantBackground[variant]})`,
        filter: isDisabled ? "grayscale(1)" : null,
      }}
      onClick={!isDisabled ? onClick : () => {}}
    >
      <div
        className={`drop-shadow-md ${variantMargin[variant]}`}
        style={
          variant !== "third"
            ? {
                textShadow:
                  "2px 0 0 #703E0B, -2px 0 0 #703E0B, 0 2px 0 #703E0B, 0 -2px 0 #703E0B, 1px 1px #703E0B, -1px -1px 0 #703E0B, 1px -1px 0 #703E0B, -1px 1px 0 #703E0B",
              }
            : null
        }
      >
        {text}
      </div>
    </button>
  );
};

export default Button;
