const InputField = ({
  label,
  errorMessage,
  placeholder,
  className,
  value,
  onChange,
  isPrimary = false,
  type,
  inputMode,
  pattern,
  step,
  isDisabled = false,
}) => {
  const height = isPrimary ? "h-14" : "h-12";
  return (
    <div className="w-full" style={{
      filter: isDisabled ? "grayscale(1)" : null,
    }}>
      <div className="text-xl">{label}</div>
      <div
        className={`rounded-2xl gradient-border overflow-hidden mt-3 ${className}`}
        style={{ backgroundColor: "transparent" }}
      >
        <input
          disabled={isDisabled}
          step={step}
          type={type}
          inputMode={inputMode}
          pattern={pattern}
          className={`${height} p-3 w-full text-sm`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            background:
              "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
          }}
        ></input>
      </div>
      {errorMessage && (
        <div className="text-red-500 text-xs mt-1 absolute">{errorMessage}</div>
      )}
    </div>
  );
};
export default InputField;
