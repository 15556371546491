import React, { useEffect, useState, useRef } from "react";

const SizeVaryingCounter = ({ maxWidth, id, finalAmount, style }) => {
  const counterRef = useRef(null); // Reference to the counter element
  const offscreenRef = useRef(null);

  function adjustFontSize() {
    const element = offscreenRef.current;
    if (element) {
      //const parentWidth = fieldWidth * scale * 0.8; // Field width in pixels
      const MIN_FONT_SIZE = 8; // Minimum font size in pixels
      const MAX_FONT_SIZE = 22; // Maximum font size in pixels

      let minFontSize = MIN_FONT_SIZE;
      let maxFontSize = MAX_FONT_SIZE;
      let fontSize;

      // Reset font size before calculation
      element.style.fontSize = MAX_FONT_SIZE + "px";

      // Binary search to find the optimal font size
      while (minFontSize <= maxFontSize) {
        fontSize = Math.floor((minFontSize + maxFontSize) / 2);
        element.style.fontSize = fontSize + "px";
        const currentWidth = element.getBoundingClientRect().width;

        if (currentWidth > maxWidth) {
          maxFontSize = fontSize - 1;
        } else {
          minFontSize = fontSize + 1;
        }
      }

      // Set font size to the maximum size that fits
      counterRef.current.style.fontSize = fontSize + "px";
    }
  }
  useEffect(() => {
    setTimeout(() => {
      adjustFontSize();
    }, 10);
  }, [finalAmount]);

  return (
    <>
      <div
        ref={offscreenRef}
        style={{
          position: "absolute",
          visibility: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {Number.parseFloat(finalAmount).toFixed(2)}
      </div>
      <div id={id} ref={counterRef} style={style}>
        {/* The counter text is handled by useCountUp */}
      </div>
    </>
  );
};

export default SizeVaryingCounter;
