import { useMemo } from "react";
import ProgressBar from "./ProgressBar";
import { Badge } from "./Badge";
import Button from "./Button";
import TickIcon from "./TickIcon";

const TaskCard = ({
  icon,
  title,
  reward,
  progress,
  subtitle,
  buttonText,
  onButtonClick,
  isCompleted = false,
}) => {
  const progressSpecificStyling = useMemo(() => {
    if (progress === undefined || progress === null) {
      return "h-12 justify-center";
    }
    if (buttonText === undefined || buttonText === null) {
      return "min-h-16 justify-between";
    }
  }, [progress]);

  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
      }}
      className={`flex flex-col gradient-border rounder-xl ${progressSpecificStyling} mx-5 mt-3 py-2 px-3`}
    >
      <div className="flex flex-row justify-between items-center mr-2">
        <div className="flex flex-row">
          <img
            src={icon}
            alt="Task icon"
            className="w-7 h-7 inline-block mr-2"
          />
          <div className="text-lg font-medium ml-2">{title}</div>
        </div>
        {progress && (
          <div>
            {progress.unitsCompleted}/{progress.goal}
          </div>
        )}
        <div className="flex flex-row items-center">
          {!isCompleted ? (
            <Badge text={reward} />
          ) : (
            <div className="border border-[#ffae17] rounded-full bg-[#ffae17]">
              <TickIcon size="1.25rem" color="white" />
            </div>
          )}
        </div>
      </div>
      {progress && (
        <div className="mt-3">
          <ProgressBar progress={progress.unitsCompleted / progress.goal} />
        </div>
      )}
      {(subtitle || buttonText) && (
        <div className="flex flex-row justify-between items-center my-2">
          {subtitle && <div className="text-sm">{subtitle}</div>}
          {buttonText && (
            <div className="flex flex-row justify-end items-center">
              <Button
                onClick={onButtonClick}
                text={buttonText}
                variant="third"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskCard;
