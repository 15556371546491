import { configureStore } from '@reduxjs/toolkit'
import { officialApi } from '../services/official';
import userSlice from '../features/userSlice';
import slotsSlice from '../features/slotsSlice';

export default configureStore({
  reducer: {
    [officialApi.reducerPath]: officialApi.reducer,
    user: userSlice,
    slots: slotsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(officialApi.middleware),
});