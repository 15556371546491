export const HeaderContainer = ({ children }) => {
  return (
    <div
      className="absolute z-[10] top-0 px-8 pb-6 w-full rounded-none border-solid backdrop-blur-[5px] rounded-b-2xl border-b-[2.5px] border-b-orange-500"
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0.32) 0%, rgba(0, 0, 0, 0.16) 100%)",
      }}
    >
      {children}
    </div>
  );
};
