const Card = ({ topLeft, topRight, bottomLeft, bottomRight }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
        overflow: "hidden", // Ensures that any protruding part of the child is clipped
      }}
      className="h-20 gradient-border rounded-2xl mx-5 mt-5 backdrop-blur-[5px] flex flex-col justify-between"
    >
      <div className="flex justify-between w-full px-3 flex-grow items-center">
        <div>{topLeft}</div>
        <div className="flex-grow text-right">{topRight}</div>
      </div>

      <div
        style={{
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
        }}
        className="h-8 gradient-border backdrop-blur-[5px] flex justify-between px-3 items-center rounded-t-lg"
      >
        <div>{bottomLeft}</div>
        <div className="ml-auto">{bottomRight}</div>
      </div>
    </div>
  );
};

export default Card;
