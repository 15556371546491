export const Badge = ({ text }) => {
  return (
    <div
      className="w-16 h-5 flex flex-row items-center justify-center bg-no-repeat bg-center bg-cover"
      style={{
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="22" fill="none"><path fill="%23FFAE17" d="M57.54 0H6.46C2.893 0 0 4.924 0 10.997v.006C0 17.076 2.893 22 6.46 22h51.08C61.109 22 64 17.076 64 11.003v-.006C64 4.924 61.109 0 57.54 0z"/></svg>')`,
      }}
    >
      {text}
    </div>
  );
};
