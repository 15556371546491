export const ImageButton = ({ imageSrc, alt, className, onClick }) => {
  return (
    <button onClick={onClick}>
      <img
        src={imageSrc}
        alt={alt}
        className={`object-cover size-full ${className}`}
      />
    </button>
  );
};
