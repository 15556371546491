import dollarIcon from "../assets/images/dollar_icon.png";

const DailyLoginRewardCard = ({ dayNumber, reward, isEnabled }) => {
  return (
    <div
      className="roundex-2xl gradient-border flex flex-col items-center justify-center gap-2 w-20 py-1.5"
      style={{
        background: !isEnabled
          ? "linear-gradient(to bottom, rgba(255,255,255,0.4), rgba(255,255,255,0.2))"
          : "linear-gradient(to bottom, #FFB617, #FF7C17)",
      }}
    >
      <div className="text-xs">Day {dayNumber}</div>
      <div className="rounded-full bg-[rgba(217, 217, 217, 0.35)]">
        <img src={dollarIcon} alt="Daily login icon" className="w-7" />
      </div>

      <div className="text-sm">${reward}</div>
    </div>
  );
};

export default DailyLoginRewardCard;
