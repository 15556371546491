import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setUserId } from '../features/userSlice';

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
        baseUrl: 'https://api.test.airdropcasino.com/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    });

    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            const refreshResult = await baseQuery({
                url: 'Authentication/refresh-token',
                method: 'POST',
                body: { refreshToken },
            }, api, extraOptions);

            if (refreshResult.data) {
                localStorage.setItem('accessToken', refreshResult.data.accessToken);
                localStorage.setItem('refreshToken', refreshResult.data.refreshToken);

                result = await baseQuery(args, api, extraOptions);
            } else {
                localStorage.clear();
            }
        } else {
            localStorage.clear();
        }
    }

    // Return the final result (either the original response or the retried one)
    return result;
};



export const officialApi = createApi({
    reducerPath: 'officialApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['settings', 'payoutHistory', 'user', 'socialMediaTasks', 'referralStatistics', 'dailyLoginStatistics'],
    endpoints: (builder) => ({
        authenticateWithTelegram: builder.mutation({
            query: ({ parentReferralCode, initData }) => {
                return ({
                    url: `Authentication/login/telegram?${initData}`,
                    body: { parentReferralCode, },
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            async onQueryStarted({ }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    const { accessToken, refreshToken } = data;

                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    dispatch(setUserId(data.userId));
                } catch (error) {
                    console.error('Failed to authenticate:', error);
                }
            },
        }),
        getUser: builder.query({
            query: (id) => `Users/${id}`,
            providesTags: ['user']
        }),
        getReferralStatistics: builder.query({
            query: ({ userId }) => `Users/${userId}/ReferralStatistics`,
            providesTags: ['referralStatistics']
        }),
        getSettings: builder.query({
            query: () => `Settings`,
            providesTags: ['settings']
        }),
        getDepositWallet: builder.query({
            query: ({ userId }) => `Users/${userId}/DepositWallets`,
        }),
        updateSettings: builder.mutation({
            query: (settings) => ({
                url: 'Settings',
                method: 'PUT',
                body: settings,
            }),
            invalidatesTags: ['settings']
        }),
        requestPayout: builder.mutation({
            query: ({ chipsToWithdraw, address, currency }) => ({
                url: 'PayoutRequests',
                method: 'POST',
                body: { chipsToWithdraw, address, currency },
            }),
            invalidatesTags: ['payoutHistory', 'user']
        }),
        getPayoutHistory: builder.query({
            query: ({ userId }) => `Users/${userId}/PayoutRequests`,
            providesTags: ['payoutHistory']
        }),
        getLivePayouts: builder.query({
            query: () => `PayoutRequests/live`,
        }),
        claimReward: builder.mutation({
            query: ({ rewardId }) => ({
                url: `ClaimableRewards/${rewardId}/claim`,
                method: 'POST',
            }),
            invalidatesTags: ['user', 'referralStatistics', 'dailyLoginStatistics']
        }),
        getUnclaimedRewards: builder.query({
            query: ({ userId }) => `Users/${userId}/ClaimableRewards/unclaimed`,
        }),
        getDailyLoginStatistics: builder.query({
            query: ({ userId }) => `Users/${userId}/DailyLogin/statistics`,
            providesTags: ['dailyLoginStatistics']
        }),
        requestNewSlotsGame: builder.mutation({
            query: ({ betSizeInChips }) => ({
                url: `SlotsGames`,
                method: 'POST',
                body: { betSizeInChips }
            }),
            invalidatesTags: ['user']
        }),
        getSocialMediaTasks: builder.query({
            query: ({ userId }) => `Users/${userId}/SocialMediaTasks`,
            providesTags: ['socialMediaTasks']
        }),

        markSocialMediaTaskAsClicked: builder.mutation({
            query: (taskId) => ({
                url: `SocialMediaTasks/${taskId}/mark-as-clicked`,
                method: 'POST',
            }),
            invalidatesTags: ['socialMediaTasks']
        }),

        completeSocialMediaTask: builder.mutation({
            query: (taskId) => ({
                url: `SocialMediaTasks/${taskId}/complete`,
                method: 'POST',
            }),
            invalidatesTags: ['user', 'socialMediaTasks']
        }),

    })
});

export const { useAuthenticateWithTelegramMutation, useGetUserQuery, useLazyGetUserQuery, useGetReferralStatisticsQuery, useClaimRewardMutation, useGetUnclaimedRewardsQuery,
    useGetSocialMediaTasksQuery, useMarkSocialMediaTaskAsClickedMutation, useCompleteSocialMediaTaskMutation, useLazyGetSocialMediaTasksQuery,
    useUpdateSettingsMutation, useRequestPayoutMutation, useGetPayoutHistoryQuery, useGetLivePayoutsQuery, useGetDailyLoginStatisticsQuery, useRequestNewSlotsGameMutation,
    useLazyGetReferralStatisticsQuery, useGetSettingsQuery, useLazyGetSettingsQuery, useGetDepositWalletQuery, useLazyGetDepositWalletQuery } = officialApi;