import ReactModal from "react-modal";
import { ImageButton } from "../ImageButton";
import closeIcon from "../../assets/images/close_icon.webp";
import "./ModalBottomSheet.css";

const ModalBottomSheet = ({ isOpen, close, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: "modal",
        afterOpen: "modal-open",
        beforeClose: "modal-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay-open",
        beforeClose: "overlay-close",
      }}
      closeTimeoutMS={500} // Duration of the animation in milliseconds
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-row justify-end mt-6 mr-6">
          <ImageButton
            imageSrc={closeIcon}
            alt="Close icon"
            onClick={close}
            className="w-8 h-8"
          />
        </div>
        <div className="flex-grow flex items-center justify-center">
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalBottomSheet;
