import ModalPopUp from "../components/ModalPopUp/ModalPopUp";
import { MoonLoader } from "react-spinners";
import TickIcon from "../components/TickIcon";
import ErrorIcon from "../components/ErrorIcon";

const PayoutStatusPopUp = ({ isOpen, close, result }) => {
  function getTransactionStatusMessage() {
    switch (result.data.status) {
      case "Completed":
        return "Your funds has been sent successfully.";
      case "WaitingForApproval":
        return "Your withdrawal is now waiting for manual verification.";
      default:
        throw new Error("Unknown transaction status");
    }
  }

  function getTitle() {
    if (result.isLoading) {
      return "Processing...";
    }
    if (result.isError) {
      return "Error";
    }
    if (result.isSuccess) {
      return "Success!";
    }
  }

  return (
    <ModalPopUp
      isOpen={isOpen}
      close={close}
      title={getTitle()}
      showCloseButton={!result.isLoading}
    >
      <div className="flex flex-col justify-between items-center text-xl text-center">
        {result.isLoading && <MoonLoader color="#FFB617" />}
        {result.isError && (
          <>
            <ErrorIcon size="10rem" color="#FF7276" />
            <div className="mt-2">{result.error.data.message}</div>
          </>
        )}
        {result.isSuccess && (
          <>
            <TickIcon size="10rem" color="#90EE90" />
            <div className="mt-2">{getTransactionStatusMessage()}</div>
          </>
        )}
      </div>
    </ModalPopUp>
  );
};

export default PayoutStatusPopUp;
