import { useLocation, useNavigate } from "react-router-dom";
import HomeHeader from "./HomeHeader";
import { ImageButton } from "./ImageButton";
import backButton from "../assets/images/back_button.png";
import StandardHeader from "./StandardHeader";
import ChipExchangeRatio from "./ChipExchangeRatio";
import Button from "./Button";
import { useCallback } from "react";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToPayoutHistory = useCallback(() => {
    navigate("/payout/history");
  }, []);

  const navigateToHome = useCallback(() => {
    navigate("/");
  }, []);

  const navigateToLivePayouts = useCallback(() => {
    navigate("/payout/live-payouts");
  });

  switch (location.pathname) {
    case "/":
      return <HomeHeader />;
    case "/deposit":
      return (
        <StandardHeader title="Deposit" rightElement={<ChipExchangeRatio />} />
      );
    case "/payout":
      return (
        <StandardHeader title="Payout" rightElement={<ChipExchangeRatio />} />
      );
    case "/payout/live-payouts":
      return (
        <StandardHeader
          title="Payout"
          rightElement={
            <Button
              variant="third"
              text="History"
              onClick={navigateToPayoutHistory}
            />
          }
        />
      );
    case "/earn":
      return <StandardHeader title="Earn" />;
    case "/friends":
      return <StandardHeader title="Friends" />;
    case "/settings":
      return (
        <StandardHeader
          title={
            <div className="flex flex-row items-center gap-3">
              <ImageButton imageSrc={backButton} onClick={navigateToHome} className="w-10 h-10"/>
              Settings
            </div>
          }
        />
      );
    case "/payout/history":
      return (
        <StandardHeader
          title={
            <div className="flex flex-row items-center gap-3">
              <ImageButton imageSrc={backButton} onClick={navigateToLivePayouts} className="w-10 h-10" />
              Payout History
            </div>
          }
        />
      );
    case "/game/slots":
      return (
        <StandardHeader
          title={<ImageButton imageSrc={backButton} onClick={navigateToHome} className="w-10 h-10" />}
        />
      );
    default:
      return null;
  }
};

export default Header;
