import { useSelector } from "react-redux";
import { useGetUserQuery } from "../services/official";
import { getMobileOperatingSystem } from "./mobileUtils";

const useReflink = () => {
    const id = useSelector((state) => state.user.id);
    const { data: user } = useGetUserQuery(id);
    return `https://t.me/CC_AC_Test_Bot/start?startapp=${user?.referralCode}`;
};

const shareOnFacebook = (urlToShare) => {
    const appLink = getFacebookDeepLink(urlToShare);
    const webLink = getFacebookWebLink(urlToShare);
    return openAppOrFallback(appLink, webLink);
};

const getFacebookDeepLink = (urlToShare) => {
    const mobileOperatingSystem = getMobileOperatingSystem();

    if (mobileOperatingSystem === 'iOS') {
        return `fb://share?link=${encodeURIComponent(urlToShare)}`;
    } else if (mobileOperatingSystem === 'Android') {
        return 'fb://facewebmodal/f?href=' + encodeURIComponent(urlToShare);
    }
}

const getFacebookWebLink = (urlToShare) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;

const shareOnTelegram = (textToShare, urlToShare) => {
    const webLink = getTelegramWebLink(textToShare, urlToShare);
    if (window.Telegram?.WebApp?.openTelegramLink) {
        window.Telegram.WebApp.openTelegramLink(webLink);
    } else {
        window.open(webLink, "_blank");
    }
};

const getTelegramWebLink = (textToShare, urlToShare) =>
    `https://t.me/share/url?url=${encodeURIComponent(
        urlToShare
    )}&text=${encodeURIComponent(textToShare)}`;


const shareOnTwitter = (textToShare, urlToShare) => {
    const webLink = getTwitterWebLink(textToShare, urlToShare);
    openLink(webLink);
};


const getTwitterWebLink = (textToShare, urlToShare) =>
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        textToShare
    )}&url=${encodeURIComponent(urlToShare)}`;

const openLink = (url) => {
    if (window.Telegram?.WebApp?.openLink) {
        window.Telegram.WebApp.openLink(url);
    } else {
        window.open(url, "_blank");
    }
};

const openAppOrFallback = (appLink, webLink) => {
    if (appLink === undefined) {
        openLink(webLink);
    }
    let didOpenApp = false;

    const handleVisibilityChange = () => {
        if (document.hidden) {
            didOpenApp = true;
            clearTimeout(timer);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);


    window.open(appLink, "_blank");

    // Fallback to web link if the app isn't opened
    const timer = setTimeout(() => {
        if (!didOpenApp) {
            openLink(webLink);
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, 1500); // Adjust the timeout as needed
};

const shareContent = (title, text, url) => {
    if (navigator.share) {
        navigator
            .share({
                title: title,
                text: text,
                url: url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    } else {
        alert('Sharing not supported on this browser.');
    }
};


export { useReflink, shareContent, shareOnFacebook, shareOnTelegram, shareOnTwitter, openLink };