import LivePayoutCard from "../components/LivePayoutCard";
import TabBar from "../components/TabBar";
import { useGetLivePayoutsQuery, useGetUserQuery } from "../services/official";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getTransactionLink } from "../utils/blockchain";

const LivePayoutsScreen = () => {
  const { id } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(id);
  const { data } = useGetLivePayoutsQuery();
  const payoutRequests = useMemo(
    () => data?.payoutRequests && [...data?.payoutRequests],
    [data?.payoutRequests]
  );

  const currencies = {
    TetherErc20: "ERC-20",
    TetherTrc20: "TRC-20",
  };

  return (
    <>
      <TabBar
        leftTitle={"Payout"}
        rightTitle={"Live payouts"}
        leftLink={"/payout"}
        rightLink={"/payout/live-payouts"}
      />
      <div className="pb-32">
        {payoutRequests
          ?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
          .map((payout, index) => (
            <LivePayoutCard
              key={index}
              currency={currencies[payout.currency]}
              amount={`${(payout.chipAmount / 57).toFixed(2)}`}
              username={user.userName}
              date={Date.parse(payout.createdAt)}
              transactionLink={
                payout.status === "Completed" &&
                getTransactionLink(
                  payout.currency,
                  payout.transactionHash,
                  payout.network
                )
              }
            />
          ))}
      </div>
    </>
  );
};

export default LivePayoutsScreen;
