import React, { useState, useMemo } from "react";
import balloonButton from "../assets/images/balloon_button.png";
import settingsButton from "../assets/images/settings_button.png";
import airdropIcon from "../assets/images/airdrop_icon.svg";
import chipIcon from "../assets/images/chip_icon.svg";
import airdropCoinIcon from "../assets/images/airdrop_coin_icon.svg";
import maleAvatar from "../assets/images/male_avatar.webp";
import { ImageButton } from "./ImageButton";
import { HeaderContainer } from "./HeaderContainer";
import { useNavigate } from "react-router-dom";
import ModalBottomSheet from "./ModalBottomSheet/ModalBottomSheet";
import { useGetUserQuery } from "../services/official";
import { useSelector } from "react-redux";
import BalanceIndicator from "./BalanceIndicator";

function HomeHeader() {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const { id } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(id, { keepPreviousData: true });

  const shortenedUserName = useMemo(() => {
    const maxLength = 10;
    if (!user?.userName) return "";
    return user?.userName.length > maxLength
      ? `${user?.userName.slice(0, maxLength)}...`
      : user?.userName;
  }, [user?.userName]);

  return (
    <>
      <HeaderContainer>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between font-medium text-center">
            <div className="flex gap-3 mt-5 text-xl text-white">
              <img
                src={maleAvatar}
                alt="User's generic avatar"
                className="object-contain shrink-0 aspect-square rounded-[200px] w-14"
              />
              <div className="my-auto">{shortenedUserName}</div>
            </div>
            <div className="flex gap-3.5 mt-6">
              <ImageButton
                alt="Balloon Button"
                className="w-12 h-12"
                imageSrc={balloonButton}
                onClick={() => setModalOpen(true)}
              />
              <ImageButton
                alt="Settings Button"
                className="w-12 h-12"
                imageSrc={settingsButton}
                onClick={() => {
                  navigate("/settings");
                }}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between pt-3">
            <BalanceIndicator
              icon={chipIcon}
              balance={
                user
                  ? user?.onlyForPlayChipBalance + user?.withdrawableChipBalance
                  : null
              }
            />
            <BalanceIndicator
              icon={airdropCoinIcon}
              balance={user?.airdropPoints}
            />
          </div>
        </div>
      </HeaderContainer>
      <ModalBottomSheet isOpen={isModalOpen} close={() => setModalOpen(false)}>
        <div className="flex flex-col gap-10 text-2xl items-center justify-center">
          <img src={airdropIcon} alt="Airdrop icon" className="w-52 h-52" />
          Airdrop coming soon...
        </div>
      </ModalBottomSheet>
    </>
  );
}

export default HomeHeader;
