import React from "react";
import Switch from "../components/Switch";
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetUserQuery
} from "../services/official";
import { useSelector } from "react-redux";

const SettingsScreen = () => {
  const userId = useSelector((state) => state.user.id);
  const { data: user } = useGetUserQuery(userId);
  const { data } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();

  return (
    <>
      <div className="flex flex-col px-5 pt-8 text-xl">
        <Switch
          text={
            user?.isTelegramUser ? "Telegram notifications" : "Email newsletter"
          }
          onClick={() =>
            updateSettings({
              areNotificationsEnabled: !data?.areNotificationsEnabled,
            })
          }
          isEnabled={data?.areNotificationsEnabled}
        />
      </div>
    </>
  );
};

export default SettingsScreen;
