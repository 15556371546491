import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import FriendsScreen from './screens/FriendsScreen';
import PayoutScreen from './screens/PayoutScreen';
import DepositScreen from './screens/DepositScreen';
import EarnScreen from './screens/EarnScreen';
import SettingsScreen from './screens/SettingsScreen';
import Header from './components/Header';
import NavigationBar from './components/NavigationBar';
import SplashScreen from './screens/SplashScreen';
import './App.css';
import SlotsScreen from './screens/SlotsScreen';
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { Provider } from 'react-redux';
import store from './app/store';
import LivePayoutsScreen from './screens/LivePayoutsScreen';
import PayoutHistoryScreen from './screens/PayoutHistoryScreen';
import Modal from "react-modal";

Modal.setAppElement("#root");

function App() {

  const [isExpanded, expand] = useExpand();
  if (!isExpanded) expand()
  const [isSplashFinished, setSplashFinished] = useState(false);

  const handleSplashFinish = () => {
    setSplashFinished(true);
  };

  return (
    <Provider store={store}>
      <div id="root">
        <Router>
          {!isSplashFinished && <SplashScreen onFinish={handleSplashFinish} />}
          {isSplashFinished && (<>
            <Header />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/friends" element={<FriendsScreen />} />
                <Route path="/payout" element={<PayoutScreen />} />
                <Route path="/payout/live-payouts" element={<LivePayoutsScreen />} />
                <Route path="/payout/history" element={<PayoutHistoryScreen />} />
                <Route path="/deposit" element={<DepositScreen />} />
                <Route path="/earn" element={<EarnScreen />} />
                <Route path="/settings" element={<SettingsScreen />} />
                <Route path="/game/slots" element={<SlotsScreen />} />
              </Routes>
            </div>
            <NavigationBar />
          </>
          )}
        </Router>
      </div>
    </Provider>
  );
}

export default App;
