import TaskCard from "./TaskCard";
import inviteFriendsIcon from "../assets/images/invite_friends_icon.svg";
import { useSelector } from "react-redux";
import {
  useGetReferralStatisticsQuery,
  useGetUnclaimedRewardsQuery,
  useClaimRewardMutation,
} from "../services/official";
import { useCallback, useMemo, useState } from "react";
import ModalBottomSheet from "./ModalBottomSheet/ModalBottomSheet";
import CongratulationsModalBottomContent from "./CongratulationsModalBottomContent";

const InviteFriendsTaskCard = ({}) => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferralStatisticsQuery({ userId });
  const { data: unclaimedRewards } = useGetUnclaimedRewardsQuery({ userId });
  const [claimReward, result] = useClaimRewardMutation();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const isGoalCompleted = useMemo(() => {
    return (
      data !== null &&
      data !== undefined &&
      data?.totalReferredUsers >= data?.nextReferralGoal
    );
  }, [data]);

  const onClaimReward = useCallback(() => {
    const oldestUnclaimedReward = unclaimedRewards?.unclaimedRewards
      ?.filter((reward) => reward?.type === "Referral")
      ?.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))[0];

    claimReward({ rewardId: oldestUnclaimedReward?.id });
    openModal();
  }, [unclaimedRewards, claimReward, openModal]);

  return (
    <>
      <TaskCard
        icon={inviteFriendsIcon}
        title={"Invite friends"}
        reward={data && `$${data?.nextReferralReward / 57}`}
        progress={{
          unitsCompleted: data?.totalReferredUsers,
          goal: data?.nextReferralGoal,
        }}
        subtitle={isGoalCompleted && "Claim your referral reward"}
        buttonText={isGoalCompleted && "Claim"}
        onButtonClick={isGoalCompleted && onClaimReward}
      />
      <ModalBottomSheet isOpen={isModalOpen} close={() => setModalOpen(false)}>
        <CongratulationsModalBottomContent result={result} chipReward={data?.nextReferralReward} />
     </ModalBottomSheet>
    </>
  );
};

export default InviteFriendsTaskCard;
