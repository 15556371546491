import Card from "./Card";
import chipIcon from "../assets/images/chip_icon.svg";
import rightArrowIcon from "../assets/images/right_arrow_icon.svg";

const PayoutHistoryCard = ({
  currency,
  amount,
  username,
  date,
  status,
  transactionLink,
}) => {
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  }).format(date);


  return (
    <Card
      topLeft={<span className="text-lg">{username}</span>}
      topRight={
        <div className="flex flex-row items-center justify-end">
          ${amount}
          <img src={chipIcon} alt="coin icon" className="w-5 ml-2" />
        </div>
      }
      bottomLeft={
        <div className="text-sm">
          {currency} <span className="text-[#FFAE17]">•</span> {formattedDate}{" "}
          <span className="text-[#FFAE17]">•</span> {status}
        </div>
      }
      bottomRight={
        transactionLink && <button
          onClick={() => {
            window.open(transactionLink, "_blank");
          }}
          className="flex flex-row items-center justify-end text-sm"
        >
          Link
          <img className="ml-2" src={rightArrowIcon} />
        </button>
      }
    />
  );
};

export default PayoutHistoryCard;
