import confettiIcon from "../assets/images/confetti_icon.svg";
import { MoonLoader } from "react-spinners";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";

const CongratulationsModalBottomContent = ({ result, chipReward }) => {
  return (
    <div className="flex flex-col gap-10 text-2xl items-center justify-center h-full">
      {result.isLoading && (
        <>
          <MoonLoader color="#FFB617" />
          Loading...
        </>
      )}
      {result.isSuccess && (
        <>
          <ScaleUpAnimation>
            <img src={confettiIcon} alt="Confetti icon" className="w-52 h-52" />
          </ScaleUpAnimation>
          <div className="flex flex-col items-center justify-center mb-8">
            Congratulations!
            <div className="text-lg">You won ${chipReward / 57}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default CongratulationsModalBottomContent;
