import reflinkIcon from "../assets/images/reflink_icon.png";
import copyToClipboardIcon from "../assets/images/copy_to_clipboard_icon.svg";
import { ImageButton } from "./ImageButton";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import TickIcon from "./TickIcon";
import { useReflink } from "../utils/socialMedia";


const ReflinkCard = () => {
  const [isCopied, setCopied] = useState(false);
  const reflink = useReflink();

  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)",
        overflow: "hidden",
      }}
      className="h-36 gradient-border rounded-2xl mx-5 mt-5 backdrop-blur-[5px] flex flex-col justify-between"
    >
      <div className="flex flex-row">
        <img src={reflinkIcon} alt="Reflink icon" className="h-full" />
        <div className="flex flex-grow flex-col justify-center items-center gap-3">
          <div className="poetsen-one-regular">Start earning</div>
          <div className="poppins-regular">Get 25$ for each friend.</div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center pb-5 pr-5 gap-3">
        <div className="poppins-regular">Referral link: </div>
        <div
          className="poppins-regular p-1 rounded"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.32)" }}
        >
          {reflink.slice(0, 13)}...
        </div>
        <div style={isCopied ? { display: "none" } : null}>
          <CopyToClipboard text={reflink}>
            <ImageButton
              imageSrc={copyToClipboardIcon}
              alt="Copy to clipboard icon"
              className="w-5 h-5"
              onClick={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            />
          </CopyToClipboard>
        </div>
        <div style={isCopied ? null : { display: "none" }}>
          <TickIcon size="1.25rem" color="white" />
        </div>
      </div>
    </div>
  );
};

export default ReflinkCard;
