import InviteFriendsTaskCard from "../components/InviteFriendsTaskCard";
import DailyLoginTaskCard from "../components/DailyLoginTaskCard";
import SocialMediaTaskCard from "../components/SocialMediaTaskCard";
import {
  shareOnFacebook,
  shareContent,
  shareOnTelegram,
  shareOnTwitter,
  openLink,
} from "../utils/socialMedia";
import { useReflink } from "../utils/socialMedia";

const EarnScreen = () => {
  const reflink = useReflink();

  return (
    <>
      <div className="pb-32">
        <div className="text-lg ml-5 mt-3">Share</div>
        <SocialMediaTaskCard
          platform="instagram"
          type="share"
          rewardInUsd={25}
          handleAction={() =>
            shareContent("Check out this app!", "It's awesome!", reflink)
          }
        />
        <SocialMediaTaskCard
          platform="facebook"
          type="share"
          rewardInUsd={25}
          handleAction={() => shareOnFacebook(reflink)}
        />
        <SocialMediaTaskCard
          platform="x"
          type="share"
          rewardInUsd={25}
          handleAction={() => shareOnTwitter("Check out this app!\n", reflink)}
        />
        <SocialMediaTaskCard
          platform="telegram"
          type="share"
          rewardInUsd={25}
          handleAction={() => shareOnTelegram("Check out this app!", reflink)}
        />
        <SocialMediaTaskCard
          platform="tiktok"
          type="share"
          rewardInUsd={25}
          handleAction={() =>
            shareContent("Check out this app!", "It's awesome!", reflink)
          }
        />
        <div className="text-lg ml-5 mt-3">Follow</div>
        <SocialMediaTaskCard
          platform="instagram"
          type="follow"
          rewardInUsd={5}
          handleAction={() => openLink("https://instagram.com")}
        />
        <SocialMediaTaskCard
          platform="facebook"
          type="follow"
          rewardInUsd={5}
          handleAction={() => openLink("https://facebook.com")}
        />
        <SocialMediaTaskCard
          platform="x"
          type="follow"
          rewardInUsd={5}
          handleAction={() => openLink("https://x.com")}
        />
        <SocialMediaTaskCard
          platform="telegram"
          type="follow"
          rewardInUsd={5}
          handleAction={() => openLink("https://telegram.org")}
        />
        <SocialMediaTaskCard platform="tiktok" type="follow" rewardInUsd={5} />
        <div className="mt-6">
          <InviteFriendsTaskCard />
          <DailyLoginTaskCard />
        </div>
      </div>
    </>
  );
};

export default EarnScreen;
