import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./SlotMachineReel.css";
import reelBorder from "../../assets/images/reel_border.png";
import reelBackground from "../../assets/images/reel_background.png";
import { reelSymbols } from "../../utils/reelSymbols";

const nameToImage = {
  BTC: require("../../assets/images/slot_machine_symbols/bitcoin.png"),
  ETH: require("../../assets/images/slot_machine_symbols/eth.png"),
  BNB: require("../../assets/images/slot_machine_symbols/bnb.png"),
  Doge: require("../../assets/images/slot_machine_symbols/doge.png"),
  XRP: require("../../assets/images/slot_machine_symbols/xrp.png"),
  Cardano: require("../../assets/images/slot_machine_symbols/cardano.png"),
  Shiba: require("../../assets/images/slot_machine_symbols/shiba.png"),
  CRO: require("../../assets/images/slot_machine_symbols/cronos.png"),
  Monero: require("../../assets/images/slot_machine_symbols/monero.png"),
  Pepe: require("../../assets/images/slot_machine_symbols/pepe.png"),
  Uniswap: require("../../assets/images/slot_machine_symbols/uniswap.png"),
};

const SlotMachineReel = forwardRef(({ isLast }, ref) => {
  const [isSpinning, setSpinning] = useState(false);
  const [highlightedPosition, setHighlightedPosition] = useState(null);
  const reelRef = useRef(null);
  const images = useMemo(
    () => reelSymbols.map((symbol) => nameToImage[symbol]),
    [nameToImage]
  );
  const symbols = useMemo(() => [...images, ...images, ...images], [images]);
  const symbolHeight = 60;

  useEffect(() => {
    const preloadImages = async () => {
      const promises = symbols.map((symbol) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = symbol;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });
      await Promise.all(promises);
    };

    preloadImages();
  }, [symbols]);

  useEffect(() => {
    if (!reelRef.current) return;
    reelRef.current.style.transform = `translateY(${
      reelRef.current.scrollHeight - 180 - images.length * symbolHeight
    }px)`;
  }, [symbols]);

  const startSpinning = (finalPosition) => {
    if (isSpinning || !reelRef.current) return;
    setHighlightedPosition(null);
    setSpinning(true);

    reelRef.current.style.transition = "transform 3s ease-out";
    reelRef.current.style.transform = `translateY(${
      reelRef.current.scrollHeight -
      180 -
      finalPosition * symbolHeight -
      images.length * symbolHeight
    }px)`;

    return new Promise((resolve) => {
      setTimeout(() => {
        if (!reelRef.current) return;
        reelRef.current.style.transition = "";
        reelRef.current.style.transform = `translateY(${
          reelRef.current.scrollHeight - 180 - finalPosition * symbolHeight
        }px)`;
        setSpinning(false);
        resolve();
      }, 3000);
    });
  };

  useImperativeHandle(ref, () => ({
    spin: async (finalPosition) => {
      await startSpinning(finalPosition);
    },
    highlightSymbol: (position) => {
      setHighlightedPosition(position);
    },
    isSpinning: () => isSpinning,
  }));

  return (
    <div>
        <div className="background-container">
          <img src={reelBackground} alt="Reel background" className="bg" />
          <img src={reelBorder} alt="Reel border" className="bg z-[3]" />
        </div>
        <div
          className="reel-container"
          style={{
            marginRight: !isLast ? "-2rem" : "0",
          }}
        >
          <div className="reel pt-3" ref={reelRef}>
            {symbols.map((symbol, index) => (
              <img
                style={
                  highlightedPosition === index - 1
                    ? {
                        animation:
                          "pulsate 0.5s infinite ease-in-out, rotate 0.5s infinite ease-in-out",
                      }
                    : null
                }
                key={index}
                src={symbol}
                alt="Symbol"
                className="symbol"
              />
            ))}
          </div>
        </div>
    </div>
  );
});

export default SlotMachineReel;
