import CopyAddressField from "../components/CopyAddressField";
import DropdownMenu from "../components/DropdownMenu";
import QrCode from "../components/QrCode";
import { useMemo, useState } from "react";
import { useGetDepositWalletQuery } from "../services/official";
import { useSelector } from "react-redux";

const DepositScreen = () => {
  const currencies = ["BTC", "ETH", "USDT (ERC-20)", "USDT (TRC-20)"];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id } = useSelector((state) => state.user);
  const { data } = useGetDepositWalletQuery(
    { userId: id },
    { keepPreviousData: true }
  );
  const addresses = [
    data?.bitcoinAddress,
    data?.ethereumAddress,
    data?.ethereumAddress,
    data?.tronAddress,
  ];
  const address = useMemo(
    () => addresses[selectedIndex],
    [addresses, selectedIndex]
  );

  return (
    <>
      <div className="flex flex-col p-5 gap-3">
        <DropdownMenu
          label="Currency"
          items={currencies}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          isCentered={false}
        />
        <CopyAddressField label="Deposit Address" address={address} />
        <div className="self-center justify-center mt-10">
          <QrCode value={address} />
        </div>
      </div>
    </>
  );
};

export default DepositScreen;
