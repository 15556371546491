import { useState } from "react";
import { ImageButton } from "./ImageButton";
import CopyToClipboard from "react-copy-to-clipboard";
import copyToClipboardIcon from "../assets/images/copy_to_clipboard_icon.svg";
import TickIcon from "./TickIcon";

const CopyAddressField = ({ address, label }) => {
  const [isCopied, setCopied] = useState(false);
  return (
    <div className="w-full">
      <div className="text-xl">{label}</div>
      <div
        className="flex flex-row items-center justify-between w-full h-14 rounded-xl p-3 text-xs mt-3"
        style={{
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2))",
        }}
      >
        {address}
        <div style={isCopied ? { display: "none" } : null}>
          <CopyToClipboard text={address}>
            <ImageButton
              imageSrc={copyToClipboardIcon}
              alt="Copy to clipboard icon"
              onClick={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            />
          </CopyToClipboard>
        </div>
        <div style={isCopied ? null : { display: "none" }}>
          <TickIcon size="1.25rem" color="white" />
        </div>
      </div>
    </div>
  );
};

export default CopyAddressField;
