import Card from "../components/Card";
import peopleIcon from "../assets/images/people_icon.png";
import diamondIcon from "../assets/images/diamond_icon.svg";
import airdropCoinIcon from "../assets/images/airdrop_coin_icon.svg";
import dollarIcon from "../assets/images/dollar_icon.png";
import { Badge } from "../components/Badge";
import ReflinkCard from "../components/ReflinkCard";
import { useGetReferralStatisticsQuery } from "../services/official";
import { useSelector } from "react-redux";

const FriendsScreen = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferralStatisticsQuery({ userId });
  return (
    <div className="overflow-auto pb-32">
      <div className="mb-8">
        <Card
          bottomLeft={<img src={diamondIcon} alt="Diamond icon" />}
          bottomRight={
            data?.totalFrozenChipReward !== undefined &&
            `$${(data?.totalFrozenChipReward / 57).toFixed(2)}`
          }
          topLeft={
            <img
              src={dollarIcon}
              className="w-7 ml-0.5"
              alt="Airdrop coin icon"
            />
          }
          topRight="Total Earned"
        />

        <Card
          bottomLeft={<img src={diamondIcon} alt="Diamond icon" />}
          bottomRight={
            <Badge
              text={
                data &&
                `${data?.totalReferredUsers} / ${data?.nextReferralGoal}`
              }
            />
          }
          topLeft={
            <img src={peopleIcon} className="w-7 ml-0.5" alt="People icon" />
          }
          topRight="Total Invited"
        />
        {data?.commissionInUsdThisMonth !== undefined && (
          <Card
            bottomLeft={<img src={diamondIcon} alt="Diamond icon" />}
            bottomRight={
              data?.commissionInUsdThisMonth !== undefined &&
              `$${data?.commissionInUsdThisMonth}`
            }
            topLeft={
              <img src={dollarIcon} className="w-7 ml-0.5" alt="Dollar icon" />
            }
            topRight="Commission This Month"
          />
        )}
      </div>
      <ReflinkCard />
    </div>
  );
};

export default FriendsScreen;
