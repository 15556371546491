import React from "react";
import NavigationItem from "./NavigationItem";
import navBarBorder from "../assets/images/navbar.svg";
import HomeNavigationItem from "./HomeNavigationItem";
import earnIcon from "../assets/images/earn_icon.svg";
import depositIcon from "../assets/images/deposit_icon.svg";
import payoutIcon from "../assets/images/payout_icon.svg";
import friendsIcon from "../assets/images/friends_icon.svg";
import { useLocation } from "react-router-dom";

function NavigationBar() {
  const location = useLocation();
  return (
    <nav
      className="flex flex-col justify-center w-full aspect-[3.505] z-[999] bottom-0 fixed"
      style={location.pathname.startsWith("/game/") ? { display: "none" } : null}
    >
      <img
        src={navBarBorder}
        alt=""
        className="object-cover absolute inset-0 w-full"
      />
      <div className="w-full absolute">
        <div className="flex flex-row justify-between items-center mb-3.5 px-[2rem]">
          <NavigationItem to="/earn" icon={earnIcon} label="Earn" />
          <div className="mr-[-2rem]">
            <NavigationItem
              to={"/deposit"}
              icon={depositIcon}
              label="Deposit"
            />
          </div>
          <HomeNavigationItem />
          <div className="ml-[-2rem]">
            <NavigationItem to={"/payout"} icon={payoutIcon} label="Payout" />
          </div>
          <NavigationItem to={"/friends"} icon={friendsIcon} label="Friends" />
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
