import primaryButtonBackground from '../assets/images/primary_button_background.svg';
import thirdButtonBackground from '../assets/images/third_button_background.svg';
import slotsCard from '../assets/images/slots_card.png';
import pokerCard from '../assets/images/poker_card.png';
import rouletteCard from '../assets/images/roulette_card.png';
import navBarBorder from '../assets/images/navbar.svg';
import homeIcon from '../assets/images/home_icon.svg';
import diceCard from '../assets/images/dice_card.png';
import earnIcon from "../assets/images/earn_icon.svg";
import depositIcon from "../assets/images/deposit_icon.svg";
import payoutIcon from "../assets/images/payout_icon.svg";
import friendsIcon from "../assets/images/friends_icon.svg";
import maleAvatar from "../assets/images/male_avatar.webp";
import chipIcon from "../assets/images/chip_icon.svg";
import airdropCoinIcon from "../assets/images/airdrop_coin_icon.svg";
import settingsButton from "../assets/images/settings_button.png";
import balloonButton from "../assets/images/balloon_button.png";
import closeIcon from "../assets/images/close_icon.webp";
import instagramLogo from "../assets/images/instagram_logo.svg";
import facebookLogo from "../assets/images/facebook_logo.svg";
import telegramLogo from "../assets/images/telegram_logo.svg";
import tikTokLogo from "../assets/images/tiktok_logo.svg";
import xLogo from "../assets/images/x_logo.svg";
import airdropIcon from "../assets/images/airdrop_icon.svg";
import copyToClipboardIcon from "../assets/images/copy_to_clipboard_icon.svg";
import arrowDownIcon from "../assets/images/arrow_down_icon.svg";
import reelBorder from "../assets/images/reel_border.png";
import reelBackground from "../assets/images/reel_background.png";
import slotsScoreBackground from "../assets/images/slots_score_background.png";
import diamondIcon from "../assets/images/diamond_icon.svg";
import inviteFriendsIcon from "../assets/images/invite_friends_icon.svg";
import calendarIcon from "../assets/images/calendar_icon.png";
import confettiIcon from "../assets/images/confetti_icon.svg";
import selectedIconBackground from "../assets/images/selected_icon_background.svg";
import backButton from "../assets/images/back_button.png";
import btcSymbol from "../assets/images/slot_machine_symbols/bitcoin.png";
import ethSymbol from "../assets/images/slot_machine_symbols/eth.png";
import bnbSymbol from "../assets/images/slot_machine_symbols/bnb.png";
import dogeSymbol from "../assets/images/slot_machine_symbols/doge.png";
import xrpSymbol from "../assets/images/slot_machine_symbols/xrp.png";
import cardanoSymbol from "../assets/images/slot_machine_symbols/cardano.png";
import shibaSymbol from "../assets/images/slot_machine_symbols/shiba.png";
import croSymbol from "../assets/images/slot_machine_symbols/cronos.png";
import moneroSymbol from "../assets/images/slot_machine_symbols/monero.png";
import pepeSymbol from "../assets/images/slot_machine_symbols/pepe.png";
import uniswapSymbol from "../assets/images/slot_machine_symbols/uniswap.png";
import rightArrowIcon from "../assets/images/right_arrow_icon.svg";

export const preloadImages = (onProgress) => {
  const images = getImages();
  const total = images.length;
  let loaded = 0;

  const promises = images.map((src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loaded += 1;
        if (onProgress) {
          onProgress(loaded);
        }
        resolve();
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${src}`);
        loaded += 1;
        if (onProgress) {
          onProgress(loaded);
        }
        resolve();
      };
    });
  });

  return { total, promise: Promise.all(promises) };
};

export const getImages = () => {
  return [
    rightArrowIcon,
    btcSymbol,
    ethSymbol,
    bnbSymbol,
    dogeSymbol,
    xrpSymbol,
    cardanoSymbol,
    shibaSymbol,
    croSymbol,
    moneroSymbol,
    pepeSymbol,
    uniswapSymbol,
    backButton,
    selectedIconBackground,
    confettiIcon,
    calendarIcon,
    inviteFriendsIcon,
    friendsIcon,
    diamondIcon,
    reelBorder,
    reelBackground,
    slotsScoreBackground,
    arrowDownIcon,
    copyToClipboardIcon,
    airdropIcon,
    instagramLogo,
    facebookLogo,
    telegramLogo,
    tikTokLogo,
    xLogo,
    closeIcon,
    settingsButton,
    balloonButton,
    maleAvatar,
    chipIcon,
    airdropCoinIcon,
    navBarBorder,
    homeIcon,
    earnIcon,
    depositIcon,
    payoutIcon,
    friendsIcon,
    primaryButtonBackground,
    thirdButtonBackground,
    slotsCard,
    pokerCard,
    rouletteCard,
    diceCard,
    // Add more imported images here
  ];
};

export default getImages;
