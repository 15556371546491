import ModalBottomSheet from "./ModalBottomSheet/ModalBottomSheet";
import calendarIcon from "../assets/images/calendar_icon.png";
import DailyLoginRewardCard from "./DailyLoginRewardCard";
import Button from "./Button";
import {
  useGetUnclaimedRewardsQuery,
  useClaimRewardMutation,
  useGetDailyLoginStatisticsQuery,
} from "../services/official";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import CongratulationsModalBottomContent from "./CongratulationsModalBottomContent";

const DailyLoginRewardsBottomSheet = ({ isOpen, onClose }) => {
  const { id: userId } = useSelector((state) => state.user);
  const { data: unclaimedRewards } = useGetUnclaimedRewardsQuery({ userId });
  const { data } = useGetDailyLoginStatisticsQuery({ userId });
  const [claimReward, result] = useClaimRewardMutation();

  const onClaimReward = useCallback(() => {
    const oldestUnclaimedReward = unclaimedRewards?.unclaimedRewards
      ?.filter((reward) => reward?.type === "DailyLogin")
      ?.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))[0];

    claimReward({ rewardId: oldestUnclaimedReward?.id });
  }, [unclaimedRewards, claimReward]);

  const dayToReward = {
    1: 10,
    2: 10,
    3: 10,
    4: 20,
    5: 20,
    6: 50,
    7: 50,
    14: 100,
    30: 200,
  };

  return (
    <ModalBottomSheet isOpen={isOpen} close={onClose}>
      {!result.isUninitialized && (
        <CongratulationsModalBottomContent
          result={result}
          chipReward={data?.nextDailyLoginReward}
        />
      )}
      {result.isUninitialized && (
        <div className="flex flex-col items-center justify-center gap-3 pt-14 px-3 text-wrap">
          <img
            src={calendarIcon}
            alt="Calendar icon"
            className="w-20 absolute top-0 mt-5"
          />
          <div className="text-2xl">Daily Rewards</div>
          <div className="text-sm">
            Accrue cash for logging in daily without skipping.
          </div>
          <div className="flex flex-wrap justify-center items-center gap-2 bt-3">
            {Object.keys(dayToReward).map((dayNumber, index) => (
              <DailyLoginRewardCard
                key={index}
                isEnabled={data?.daysInARow.toString() === dayNumber}
                dayNumber={dayNumber}
                reward={dayToReward[dayNumber]}
              />
            ))}
          </div>
          <div className="w-[80%] my-4">
            <Button text="Collect" onClick={onClaimReward} />
          </div>
        </div>
      )}
    </ModalBottomSheet>
  );
};

export default DailyLoginRewardsBottomSheet;
