const BalanceIndicator = ({ icon, balance }) => {
  return (
    <div className="flex flex-row justify-center">
      <div
        style={{
          background: "linear-gradient(to bottom, #FFB617, #FF7C17)",
        }}
        className="flex h-max rounded-2xl pr-0.5 pl-[0.06rem] py-[0.06rem] items-center w-32"
      >
        <div className="flex flex-row justify-center items-center rounded-2xl w-full bg-[#4A2D69]">
          <div className="flex items-center">
            <img
              src={icon}
              alt="Balance icon"
              className="w-7 h-7 inline-block"
            />
          </div>
          <div className="flex-grow text-sm font-medium text-center mr-2">
            {balance}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceIndicator;
