import Card from "../components/Card";
import dollarIcon from "../assets/images/dollar_icon.png";
import rightArrowIcon from "../assets/images/right_arrow_icon.svg";
import { useMemo } from "react";
import { getHourDifference as getHoursPassed } from "../utils/time";

const LivePayoutCard = ({
  currency,
  amount,
  username,
  date,
  transactionLink,
}) => {
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  }).format(date);

  const hoursAgo = useMemo(() => {
    const hoursPassed = getHoursPassed(date);
    if (hoursPassed < 1) {
      return "Recently";
    }
    if (hoursPassed === 1) {
      return "1 hour ago";
    }

    return `${hoursPassed} hours ago`;
  }, [date]);

  return (
    <Card
      topLeft={<span className="text-lg">{username}</span>}
      topRight={
        <div className="flex flex-row items-center justify-end">
          ${amount}
          <img src={dollarIcon} alt="dollar icon" className="w-5 ml-2" />
        </div>
      }
      bottomLeft={
        <div className="text-sm">
          {currency} <span className="text-[#FFAE17]">•</span> {formattedDate}{" "}
          <span className="text-[#FFAE17]">•</span> {hoursAgo}
        </div>
      }
      bottomRight={
        <button
          onClick={() => {
            window.open(transactionLink, "_blank");
          }}
          className="flex flex-row items-center justify-end text-sm"
        >
          Link
          <img className="ml-2" src={rightArrowIcon} />
        </button>
      }
    />
  );
};

export default LivePayoutCard;
